const _temp0 = require("./css/app.css");
const _temp1 = require("./css/control-panel.css");
const _temp2 = require("./css/gravatar-spinner.css");
const _temp3 = require("./css/gravatar.css");
const _temp4 = require("./css/header.css");
const _temp5 = require("./css/image-upload-options.css");
const _temp6 = require("./css/modal.css");
const _temp7 = require("./css/nil-role.css");
const _temp8 = require("./css/options.css");
const _temp9 = require("./css/roles.css");
const _temp10 = require("./css/site.css");
const _temp11 = require("./css/tags.css");
const _temp12 = require("./css/tips.css");
module.exports = {
  "app": _temp0,
  "control-panel": _temp1,
  "gravatar-spinner": _temp2,
  "gravatar": _temp3,
  "header": _temp4,
  "image-upload-options": _temp5,
  "modal": _temp6,
  "nil-role": _temp7,
  "options": _temp8,
  "roles": _temp9,
  "site": _temp10,
  "tags": _temp11,
  "tips": _temp12
}