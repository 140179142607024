import container from './container.js';
import fallback from './fallback.js';
import fallbacks from './fallbacks.js';
import freetext from './freetext.js';

export default {
    container,
    fallback,
    fallbacks,
    freetext
};
